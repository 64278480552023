import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import * as crypto from 'crypto-js';
import * as moment from 'moment';
import { MissingStringsType } from 'src/app/models/helper.model';


@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  loading: HTMLIonLoadingElement;

  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController,


  ) { }

  /**
   * To filter data based on ID of object
   * @param activeIdList List of ids to filter
   * @param serviceList List of data need to be filtered, must contain ID keys
   * @returns Filtered list
   */
  filterArrayByArrayIDs(activeIds, serviceList) {
    const result = serviceList.filter(({ ID }) => activeIds.includes(ID));
    return result;
  }

  /**
   * To replace active ids with actual objects
   * @param activeIds List of ids to Map
   * @param serviceList List of data need to be mapped
   * @param key Key name as identifier ex: ID
   * @returns new activeids list with objects
   */
  arrayIdObjMapper(activeIds, serviceList, key) {
    activeIds.map((obj, index) => {
      let item = serviceList.find((ele) => {
        return ele[key].toString() === obj.toString()
      })
      if (item) {
        activeIds[index] = item
      }
    });
    return activeIds;
  }

  getObjByItemId(activeId, serviceList, key) {
    let item = serviceList.find((ele) => {
      return ele[key].toString() === activeId.toString()
    })
    return item ? item : activeId;
  }

  translateOptionsItem(optionsArray, serviceList) {

    if (optionsArray && optionsArray.length > 0) {

      optionsArray.forEach(item => {
        item.Item = this.getObjByItemId(item.Item, serviceList, "ID");
        item.min = item.MinChoices ? item.MinChoices : 0;
        item.max = item.MaxChoices ? item.MaxChoices : 1;
        item.separate = item.Separate ? item.Separate : false;
      });

    }
    return optionsArray;
  }

  getRandomNumber() {
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  }

  percentageToValue(percent, total) {
    return ((percent / 100) * total);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  async presentLoading(message?) {
    this.loading = await this.loadingController.create({
      cssClass: 'loader',
      message: message ? message : null,
      duration: 2000
    });
    await this.loading.present();
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Alert',
      message: message,
      buttons: ["OK"]
    });
    await alert.present();
  }

  async presentAlertAdvance(options) {
    const alert = await this.alertController.create(options);
    await alert.present();
  }

  /**
   * To test if provided url is valid EO url
   * @param url URL
   * @returns boolean
   */
  checkIfEoUrl(url: string) {
    url = url.toLowerCase();
    let str = url ? url.split("#/") : null;
    let fragmentData;
    if (str) {
      fragmentData = str[1] ? str[1].split("-") : null;
    }
    try {
      if (str && str.length > 0 && fragmentData && fragmentData[1].includes("t")) {
        return true;
      } else if(str && str.length > 0 && fragmentData && fragmentData[1].includes("all")) {
        return true;
      } else if(str && str.length > 0 && fragmentData && !isNaN(Number(fragmentData[1]))) {
        return true;
      }else {
        return false;
      }
    } catch (error) {
      return false;
    }

  }

  openExternalLink(url: string) {
    if (this.validURL(url)) {
      window.open(url,'_system', 'location=yes'); return false;
    }
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  /**
   * To implement nand gate
   * @param a Input a
   * @param b Input b
   * @returns Boolean Output
   */
  nandGate(a:boolean,b:boolean) {
    return !(a && b)
  }

  /**
   * To remove any duplicate item in array
   * @param array input array
   * @returns output processed array
   */
  removeDuplicatesFromArray(array : Array<any>) {
    return [...new Set(array)];
  }

  /**
   * To apply rounding based on round setting and price
   * @param price Price to be round
   * @param round this.constants.siteInfo.rounding
   * @returns roundedValue
   */
  applyRounding(price,round) {
    let sign = price >= 0 ? 1.0 : -1.0;
    let roundedValue = Math.floor( Math.floor(price + round * sign/2.0) /round) * round;
    return roundedValue;
  }

   /**
   * To apply rounding to price for nearest cents
   * @param price Price to be round
   * @returns roundedValue
   */
   applyRoundingNearestCents(price) {
    let roundedValue = Math.round(price);
    return roundedValue;
  }

  /**
   * To generate password hash based on password and salt
   * @param password password text
   * @param salt other pass phrase 
   * @returns password hash
   */
  createPasswordHash(password, salt) {
    let hash;
    if (password && salt) {
      hash = crypto.SHA256(password,salt).toString();
    }
    return hash;
  }

  /**
   * To generate base64 PIN hash based on PIN and SiteId
   * @param pin 
   * @param siteId 
   * @returns base64 PIN hash
   */
  createPinHash(pin, siteId) {
    let hash;
    if (pin && siteId) {
      let msgText = siteId + "-" + pin;
      hash = crypto.SHA224(msgText).toString(crypto.enc.Base64);
    }
    
    return hash;
  }

  /**
   * Converts cents to decimal value 
   * @param cent number
   * @returns number
   */
  centsToDecimal(cent) {
    return cent * 0.01;
  }

  /**
   * To test valid email
   * @param email email to test
   * @returns match result
   */
  validEmail(email: string) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  /**
   * To generate random cardnumber for tevalis crm
   * @returns random number string 
   */
  generateCardNumberForNewUser() {
    return Math.floor((Math.random() * 10000000) + 1).toString();
  }

  /**
   * For Epoch & Unix Timestamp Conversion
   * @param dateTime string
   * @returns string  
   */
  getUtcTimestampFormatX(dateTime: string) {
    if (dateTime != '') {
      return moment.utc(dateTime).format("X");
    } else {
      return "-62135596800000+0000";
    }
  }

  /**
   * Gives x times or array of each elements 
   * @param array 
   * @param times 
   * @example [['','',''],['','',''],['','','']]
   */
  arrayLinearMultiplier(array: any[], times: number){
    return array.map((element)=>{
      return new Array(times).fill(element)
    });
  }

   /**
   * Gives x times of an array of each elements contained in an array
   * @param array 
   * @param times 
   * @example [[['','','']],[['','','']],[['','','']]]
   */
   arrayLinearMultiplier2(array: any[], times: number){
    return array.map((element)=>{
      return [new Array(times).fill(element)];
    });
  }

  /**
   * To check if all string found in list of strings
   * @param allStrArr All string list 
   * @param requiredStrings Items to be searched
   * @param isCaseInSensitive If true, makes lowercase
   * @returns MissingStringsType
   */
  checkAllMissingStrings(allStrArr: string[], requiredStrings: string[], isCaseInSensitive?: boolean): MissingStringsType {
    const missingStrings: string[] = [];
    if (isCaseInSensitive) {
      requiredStrings = requiredStrings.map(str => str.toLowerCase());
      allStrArr = allStrArr.map(str => str.toLowerCase());
    }
    const allStringSet = new Set(allStrArr);
    for (const item of requiredStrings) {
      if (!allStringSet.has(item)) {
        missingStrings.push(item);
      }
    }
    const checkAllStrings: MissingStringsType = {
      hasAll: missingStrings.length === 0,
      missingStrings: missingStrings
    }

    return checkAllStrings;
  }
}
