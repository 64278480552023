import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Data, ListType, SiteData, TevalisMemberType } from 'src/app/models/data.model';
import { CustomHttpService } from '../customhttp/custom-http.service';
import { ConstantsService } from '../shared/constants/constants.service';
import { UtilityService } from '../utility/utility.service';
import { Location } from "@angular/common";
import { MenuMetaDataType } from 'src/app/models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  // Site details..
  siteData: SiteData;
  menuData: any;
  companyTitle: { title: string, subTitle: string };

  menuCategories: Array<any>;

  selectedCategory: any;
  selectedProduct: any;


  // Item related var
  itemPath: Array<any> = [];


  // Options..
  categoryOptions: Array<any>;
  allAncestors: Array<any>;


  //Currency..
  currencyData: any;

  //Comments..
  blockComments: boolean = true;

  // root menu element
  rootMenuElement;

  // App URL
  appUrl: URL;

  // App URL fragment
  appUrlFragment: string;

  // App URL query param
  loyaltyEmailFromSearchParam: string;

  //stripe vars
  stripeClientSecret;
  stripeTotalAmount;

  // Table list data
  tableLocations;
  selectedTable : ListType;
  tableAuthPin;

  // Surcharge..
  creditcardSurchargePercentage: number = null;

  // Loyalty..
  isTevalisLoyaltyActive: boolean = false;
  tevalisLoyaltyUserData: TevalisMemberType;

  // CartState..
  isCartEnabled: boolean = true;

  //MenuMetaData
  menuMetaData: MenuMetaDataType;
  menuMetaDataObject: any;

  //PickupFutureDelay
  pickupFutureDelay = 30;

  //MenuBgImage
  menuBgImagePath = this.constants.placeholderBgImagePath;


  constructor(
    private constants: ConstantsService,
    private customHttp: CustomHttpService,
    public utilityService: UtilityService,
    public alertController: AlertController,
    public location: Location,
  ) { }

  getSiteMenus(siteInfoPayload) {
    return new Promise<any>((resolve, reject) => {
      let data: Data = { url: '', data: '' };
      data.url = this.constants.STORE_API_BASE_URL + "/appQR";
      data.data = siteInfoPayload;

      this.customHttp.postDataWithHeaders(data, this.constants.menuRequestHeaders).subscribe(
        (resp: any) => {
          resolve(resp);
        },
        // Error Case..
        (error) => {
          reject(error);
        }
      );
    });
  }

  generateSitePayload(siteData: SiteData) {
    return `siteID=${siteData.siteId}&locationID=${siteData.locationId}&appBuild=${siteData.appBuild}`
  }

  generateImageUrl(siteId, imageId, placeholderImagePath?) {
    if (siteId && imageId) {
      return this.constants.STORE_API_BASE_URL + "/assets/" + siteId + "/" + imageId;
    } else {
      if (placeholderImagePath) {
        return placeholderImagePath;
      }
      return this.constants.placeholderImagePath;
    }
  }

  generateThumbImageUrl(siteId, thumbId, placeholderImagePath?) {
    if (siteId && thumbId) { 
      return this.constants.STORE_API_BASE_URL + "/assets/" + siteId + "/" + thumbId;
    } else { 
      if (placeholderImagePath) {
        return placeholderImagePath;
      }
      return this.constants.placeholderImagePath;
    }
  }

  generateCategoriesForHomePage() {
    let categoryIds = this.menuData.Menu ? this.menuData.Menu[0].Children : null;
    if (categoryIds) {
      this.menuCategories = this.utilityService.filterArrayByArrayIDs(categoryIds, this.menuData.Menu);
    }
  }

  generateMenuListForCategory(categoryObj) {

    if (categoryObj && categoryObj.Children) {
      let items = this.utilityService.filterArrayByArrayIDs(categoryObj.Children, this.menuData.Menu);
      console.log(items);
      return items;
    }
  }

  /**
   * Process menudata and return processed json
   * @param menuData Menu object recieved from api
   * @returns Mapped Menu Object
   */
  mapParentChildren(menuData) {

    if (menuData.Menu) {
      menuData.Menu.forEach(item => {

        // assigning child
        if (item.Children) {
          item.Children = this.utilityService.arrayIdObjMapper(item.Children, menuData.Menu, "ID");
        }

        // assigning parent
        if (item.Parents) {
          item.Parents = this.utilityService.arrayIdObjMapper(item.Parents, menuData.Menu, "ID");
        }

        // asssigning options
        if (item.Options) {
          if (item.Options.length > 0) {
            item.Options = this.utilityService.translateOptionsItem(item.Options, menuData.Menu);
          }
        }

        // assigning requiredItems
        if (item.ReqItems) {
          item.ReqItems = this.utilityService.arrayIdObjMapper(item.ReqItems, menuData.Menu, "ID");
        }

        // check if alcohol product
        if (item.Alcohol && item.Alcohol === true) {
          item.isAlcohol = true
        } else {
          item.isAlcohol = false
        }

      });
    }

    console.log("mapParentChildren : ", menuData);


    return menuData;

  }

  /**
   * To get list of options associated with product
   * @param productItem Selected item
   * @returns List of options associated with product item
   */
  generateProductOptions(productItem) {
    let options;

    if (productItem && productItem.Parents && productItem.Parents.length > 0) {
      options = [];
      productItem.Parents.forEach(element => {
        if (element.Options) {
          options.push(element);
        }
      });
    }

    return options ? options : null;
  }

  /**
   * To get list of options associated with category
   * @param categoryItem Selected category
   * @returns List of options associated with product category
   */
  generateCategoryOptions(categoryItem) {
    let options;

    if (categoryItem && categoryItem.Options && categoryItem.Options.length > 0) {
      options = [];
      categoryItem.Options.forEach(element => {
        if (element) {
          options.push(element);
        }
      });
    }
    console.log("generateCategoryOptions : ", options);

    return options ? options : null;
  }

  generateAncestorsWithOptions(allAncestors) {
    let ancestors;

    if (allAncestors && allAncestors.length > 0) {
      ancestors = [];
      allAncestors.forEach(ancestorItem => {

        if (ancestorItem.Options && ancestorItem.Options.length > 0) {
          ancestors.push(ancestorItem);
        }

      })
    }
    console.log("generateAncestorsWithOptions : ", ancestors);

    return ancestors ? ancestors : null;
  }

  generateOptionsFromAncestor(ancestorItem) {
    let options = [];

    ancestorItem.Options.forEach(element => {
      if (element) {
        // process options for UI
        element.selectionCount = 0;
        // adding checkbox var
        if (element.Item && element.Item.Children && element.Item.Children.length > 0) {
          element.Item.Children.forEach(optionItem => {
            optionItem.isChecked = false;
          });
          options.push(element);
        }
      }
    })

    return options;
  }

  /**
   * To get list of options associated with category
   * @param allAncestors All Ancestors(node) List
   * @returns List of options associated with product category
   */
  generateOptionsFromAllAncestors(allAncestors) {
    let options;

    if (allAncestors && allAncestors.length > 0) {
      options = [];
      allAncestors.forEach(ancestorItem => {

        if (ancestorItem.Options && ancestorItem.Options.length > 0) {
          ancestorItem.Options.forEach((element) => {
            if (element) {
              // process options for UI
              element.selectionCount = 0;
              // adding checkbox var
              if (element.Item && element.Item.Children && element.Item.Children.length > 0) {
                
                element.Item.Children.forEach(optionItem => {
                  optionItem.isChecked = false;
                });

                // upsell flag/attribute..
                if (element.Item.Title[this.constants.languageIndex].toLowerCase().includes(this.constants.upsellPopupUniqueText)) {
                  element.isUpsell = true;
                } else {
                  element.isUpsell = false;
                }
                console.log("upsell flag/attribute index : ",element.Item.Title[this.constants.languageIndex].toLowerCase() + " : " ,element.Item.Title[this.constants.languageIndex].toLowerCase().includes(this.constants.upsellPopupUniqueText));
                options.push(element);
              }
            }
          })
        }

      })
    }
    console.log("generateOptionsFromAllAncestors : ", options);

    return options ? options : null;
  }

  pushToItemPath(item) {
    this.itemPath.push(item);
    console.log("pushToItemPath : ",this.itemPath);

  }

  popFromItemPath() {
    this.itemPath.pop();
    console.log("popFromItemPath : ", this.itemPath);

  }

  /**
   * To generate a list of all Ancestors of a node.
   * @param node Selected Node 
   * @returns Generate a list of Ancestors associated
   */
   findAllAncestors(node) {
    // base condition : if its root node
    let nodes = [node];
    // console.log("findAllAncestors : ",node.ID + " | " + node.Title[0], nodes.length > 0);
    for (let pi in node.Parents) {
      let p = node.Parents[pi];
      let anc = this.findAllAncestors(p);
      nodes.push(...anc);
    }
    return nodes;
  }

  /**
   * To generate a list of all Inheritable Ancestors of a node.
   * @param node Selected Node 
   * @returns Generate a list of Ancestors associated
   */
   findAllInheritableAncestors(node) {
    // base condition : if its root node
    let nodes = this.checkMenuItemInheritability(node) ?  [node]:  [];
    // console.log("findAllInheritableAncestors : ",node.ID + " | " + node.Title[0], this.checkMenuItemInheritability(node));
    for (let pi in node.Parents) {
      let p = node.Parents[pi];
      let anc = this.findAllInheritableAncestors(p);
      nodes.push(...anc);
    }
    return nodes;
  }

  /**
   * To generate a list of all Ancestors of a node.
   * @param node Selected Node 
   * @param itemPath Path or list of nodes to backtrack
   * @returns Generate a list of Ancestors associated with path
   */
  findAllAncestorsOnPathOld(node, itemPath) {
    // base condition : if its root node
    // let nodes =   [node];
    let nodes =   this.checkMenuItemInheritability(node) ?  [node]:  [];
    console.log("findAllAncestorsOnPath : ",node.Title[0] + " --> " + node.ID, nodes.length > 0);
    
    let isOnPath = itemPath.includes(node);
    for (let pi in node.Parents) {
      let p = node.Parents[pi];
      if (p.ID == 0 && !isOnPath) return null;
      let anc = this.findAllAncestorsOnPath(p, itemPath);
      if (anc == null) {
        if (isOnPath) continue;
        return null;
      }
      nodes.push(...anc);
    }
    return nodes;
  }

  findAllAncestorsOnPath(node, itemPath) {
    itemPath = [...itemPath, node];
    // console.log("Path Items: ", itemPath);
    return this._branchAncestors(node, itemPath).ancestors;
  }

  _branchAncestors( node, path) {

    let branchAvoidsTop = node != this.rootMenuElement;
    
    let branchAncestors = this.checkMenuItemInheritability(node) ?  [node]:  [];

    if(node.Parents)
    node.Parents.forEach(p => {
        // Here we are recursing up the parents
      var tup = this._branchAncestors(p, path);

      // Was one of the ancestors the root node?
      if (!tup.branchAvoidsTop) {
        // If item is not on path, dump the ancestors and alert any descendants
        if (!path.includes(node)) return {branchAvoidsTop: false, ancestors: []};
        // Record that this branch hits the root note.
        branchAvoidsTop = false;
      }
      // Add ancestors to list
      if ( tup.ancestors.length != 0)
        branchAncestors = [...branchAncestors,...tup.ancestors];
    });

    return {
      branchAvoidsTop: branchAvoidsTop,
      ancestors :branchAncestors,
    };
  }

  /**
   * To generate a list of all Inheritable Descendants of a node.
   * @param node Selected Node 
   * @returns Generate a list of Descendants associated
   */
   findAllInheritableDescendants(node) {
    // base condition : if its root node
    let nodes = this.checkMenuItemInheritability(node) ?  [node]:  [];
    // console.log("findAllInheritableDescendants : ",node.ID + " | " + node.Title[0], this.checkMenuItemInheritability(node));
    for (let pi in node.Children) {
      let p = node.Children[pi];
      let dec = this.findAllInheritableDescendants(p);
      nodes.push(...dec);
    }
    return nodes;
  }


  /**
   * Get price string based on currency and other properties
   * @param cents Price value in cents
   * @param plus Weather to add plus in price
   * @returns Price string
   */
  getFormatedPrice(cents: number, plus : boolean = false) {

    let priceString;
    if (cents != 0) {
      priceString = 
      (cents < 0 ? "-" : (plus ? "+" : "")) + 
      (!this.currencyData.After ? this.currencyData.Symbol : "") + 
      ((cents * (cents < 0 ? -1 : 1)) / Math.pow(10, this.currencyData.Decimals)).toFixed(this.currencyData.Decimals) + 
      (this.currencyData.After ? this.currencyData.Symbol : "");
    } else {
      priceString = 
      (cents == 0 ? (plus ? "+" : "") : "") + 
      (!this.currencyData.After ? this.currencyData.Symbol : "") + 
      (cents).toFixed(this.currencyData.Decimals) + 
      (this.currencyData.After ? this.currencyData.Symbol : "");
    }
    

    return priceString;
  }


  // /**
  //  * To check if item is available to show on list based on Day, Time, Location and Availability
  //  * @param item 
  //  * @returns boolean true/false
  //  */
  // checkMenuItemAvailability(item) : boolean {
  //   if (!item.InheritTimes) { // checking if InheritTimes exists
  //     return this.withInLocation(item);
  //   } else {
  //     let InheritTimes = item.InheritTimes[0];
  //     if (InheritTimes.Days && InheritTimes.Days.length > 0) {
  //       const date = new Date();
  //       let day = date.getDay();

  //       // to check day
  //       let isWithInDay = InheritTimes.Days.includes(day);
  //       // to check time
  //       let isWithInTime = this.withInTime(InheritTimes, date);
  //       // to check location
  //       let isWithInLocation = this.withInLocation(item);
        
  //       console.log("checkMenuItemAvailability : ", item.Title[0], isWithInDay,isWithInTime,isWithInLocation);

  //       return isWithInDay && isWithInTime && isWithInLocation;

  //     }
  //   }
  //   return false;
  // }


    /**
   * To check if item is Inheritable to show on list based on Day, Time, Location and Availability
   * @param item 
   * @returns boolean true/false
   */
  checkMenuItemInheritability(item) : boolean {
    if (!item.InheritTimes) { // checking if InheritTimes exists
      return this.withInLocation(item);
    } else {
      
      // to look into multiple time conditions added in EO admin (fixed)
      for (let index = 0; index < item.InheritTimes.length; index++) {

      let InheritTimes = item.InheritTimes[index];
        
        if (InheritTimes.Days && InheritTimes.Days.length > 0) {
          const date = new Date();
          let day = date.getDay();

          // to check day
          let isWithInDay = InheritTimes.Days.includes(day);
          // to check time
          let isWithInTime = this.withInTime(InheritTimes, date);
          // to check location
          let isWithInLocation = this.withInLocation(item);

          console.log("checkMenuItemInheritability : ",item.Title[0], isWithInDay,isWithInTime,isWithInLocation);
          
          if (isWithInDay && isWithInTime && isWithInLocation) {
            return true;
          }

        }
      }

    }
    return false;
  }

  private withInTime(InheritTimes, date) {

    let start = this.hasStart(InheritTimes)
    ? new Date(
      InheritTimes.StartYear ? InheritTimes.StartYear : date.getFullYear(), 
      InheritTimes.StartMonth ? InheritTimes.StartMonth : date.getMonth(),
      InheritTimes.StartDay ? InheritTimes.StartDay : date.getDate(), 
      InheritTimes.StartHour ? InheritTimes.StartHour : date.getHours(), 
      InheritTimes.StartMinute ? InheritTimes.StartMinute : date.getMinutes()
      )
    : null;

    let end = this.hasEnd(InheritTimes)
    ? new Date(
      InheritTimes.EndYear ? InheritTimes.EndYear : date.getFullYear(), 
      InheritTimes.EndMonth ? InheritTimes.EndMonth : date.getMonth(),
      InheritTimes.EndDay ? InheritTimes.EndDay : date.getDate(), 
      InheritTimes.EndHour ? InheritTimes.EndHour : date.getHours(), 
      InheritTimes.EndMinute ? InheritTimes.EndMinute : date.getMinutes()
      )
    : null;

    // console.log("withInTime : ", start, end);

    return (start != null && end != null && (end < start)) !=
    (start ?  start>date: false) !=
    (end ?  end>date: true);
    
  }

  private hasStart(InheritTimes) {
    return (InheritTimes.StartMinute || InheritTimes.StartHour || InheritTimes.StartDay || InheritTimes.StartMonth || InheritTimes.StartYear);
  }

  private hasEnd(InheritTimes) {
    return (InheritTimes.EndMinute || InheritTimes.EndHour || InheritTimes.EndDay || InheritTimes.EndMonth || InheritTimes.EndYear);
  }

  private withInLocation(item) : boolean {
    // if (item.Locations) {
    //   console.log(item.Title[0] + " : " 
    //   + item.Locations.includes(this.constants.siteCreed.locationId) + " " 
    //   + this.constants.siteCreed.locationId + " "
    //   + item.Locations.join(", ")
    //   );
      
    // }
    return item.Locations ? item.Locations.includes(this.constants.siteCreed.locationId) : true;
  }

 
  // Update Site Info..
  setSiteInfo(menuData) {
    this.setCurrencyData(menuData);
    this.setSiteThemeColor(menuData);
    this.setCommentsSetting(menuData);
    this.setSiteDataVersion(menuData);
    this.setSiteDataRounding(menuData);
    this.setSiteDataTip(menuData);
    this.setSiteDataPaymentInfo(menuData);
    this.setSiteDataTableLocations(menuData);
    this.setSiteDataCreditcardSurcharge(menuData);
    this.setSiteDataTevalisLoyalty(menuData);
  }

  // Update Currency settings..
  setCurrencyData(menuData) {
    if (menuData && menuData.Currency) {
      this.currencyData = menuData.Currency;
    }
  }

  // Update Site theme color..
  setSiteThemeColor(menuData) {
    if (menuData && menuData.Currency) {
      this.constants.siteInfo.siteThemeColor = menuData.Colour;
    }
  }

  // Update comments setting..
  setCommentsSetting(menuData) {
    this.blockComments = menuData.BlockComments;
  }

  // Update version..
  setSiteDataVersion(menuData) {
    if (menuData && menuData.Version) {
      this.constants.siteInfo.version = menuData.Version;
    }
  }

  // Update Rounding setting..
  setSiteDataRounding(menuData) {
    if (menuData && menuData.Rounding) {
      this.constants.siteInfo.rounding = menuData.Rounding;
    }
  }

  // Update Tip setting..
  setSiteDataTip(menuData) {
    if (menuData && menuData.Tip) {
      this.constants.siteInfo.tip = menuData.Tip;
    }
  }

  // Update Payment details..
  setSiteDataPaymentInfo(menuData) {
    if (menuData && menuData.StripePubKey) {
      this.constants.stripePubKey = menuData.StripePubKey;
    }
    if (menuData && menuData.StripeClientID) {
      this.constants.stripeClientID = menuData.StripeClientID;
    }
  }

  // Update TableLocations setting..
  setSiteDataTableLocations(menuData) {
    if (menuData && menuData.Locations) {
      this.tableLocations = this.generateTableLocationsList(menuData.Locations);
    }
  }

  // Update creditcardSurcharge setting..
  setSiteDataCreditcardSurcharge(menuData) {
    if (menuData && menuData.Surcharge) {
      this.creditcardSurchargePercentage = this.utilityService.centsToDecimal(menuData.Surcharge);
    }
  }

  // Update Tevalis Loyalty setting..
  setSiteDataTevalisLoyalty(menuData) {
    if (menuData && menuData.EmailLoyalty) {
      this.isTevalisLoyaltyActive = menuData.EmailLoyalty;
    }
  }

  setMenuMetaData(menuData) {
    if (menuData && menuData.Menu && menuData.Menu[0].Parents && menuData.Menu[0].Parents.length > 0) {
      const lookupList = [...menuData.Menu[0].Parents]
      lookupList.forEach(lookupItem => {
        if (lookupItem.Title[0]==="MenuMetaData") {
          try {
            this.menuMetaDataObject = lookupItem;
            this.menuMetaData = JSON.parse(lookupItem.Long[0]);
          } catch (error) {
            console.log("menuMetaData error",error);
          }
        }
      });
    }
    console.log("menuMetaData",this.menuMetaData);
  }



  /**
   * To apply PriceInc operation based on factors affecting price like magnitude and PriceInc
   * @param product any
   * @param ancestoresList Array<any>
   * @returns appliedAccumulatedPrice Number
   */
  calculatePriceAncestors(product, ancestoresList: Array<any>): number {
    if(product && ancestoresList) {
      if (ancestoresList.length > 0) {
        
        const priceAccumulated = ancestoresList.reduce((priceAccmulator,ancestor)=>{
          if (ancestor.PriceInc) {
            

            // flat case
            if (ancestor.PriceIncMag === 0 || ancestor.PriceIncMag === undefined) {
              priceAccmulator = priceAccmulator +  ancestor.PriceInc
            }

            // %age case
            if (ancestor.PriceIncMag && ancestor.PriceIncMag === 100) {
              priceAccmulator = priceAccmulator * (1.0 + ancestor.PriceInc/(ancestor.PriceIncMag * 100))
            }

          }
          return priceAccmulator;
        },product.Price); // reduce end here

        let sign = priceAccumulated >= 0 ? 1.0 : -1.0;
        let round = this.constants.siteInfo.rounding;

        let appliedAccumulatedPrice = Math.floor( Math.floor(priceAccumulated + round * sign/2.0) /round) * round;

        return appliedAccumulatedPrice;

      } 
      
    }
  }


  /**
   * To set language if more then one language there.
   * @param menuData menuData
   */
  async processLanguageInformation(menuData) {
    let haveMultipleLanguage = false;
    if (menuData && menuData.Languages &&  menuData.Languages.length > 1) {
      this.presentAlertRadioLanguage(menuData.Languages);
      haveMultipleLanguage = true;
      return haveMultipleLanguage;
    } else {
      return haveMultipleLanguage;
    }
  }

  /**
   * Show alert depending on list of languages available.
   * @param languageList menuData.Languages
   */
  async presentAlertRadioLanguage(languageList) {

    let languageRadioList = [];
    let selectedLanguageIndex = 0;

    if (languageList && languageList.length > 0) {
     
      languageList.forEach((element, index) => {
        let languageRadioObject = {
          name: element.Name,
          type: 'radio',
          label: element.Name,
          value: index,
          checked: index === 0 ? true : false,
          handler: () => {
            selectedLanguageIndex = index;
          }
        }
  
        languageRadioList.push(languageRadioObject);
      });

    }

    const alert = await this.alertController.create({
      cssClass: 'language-popup-custom-class',
      header: 'Select Language',
      inputs: languageRadioList,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            console.log('presentAlertRadioLanguage ok : ', selectedLanguageIndex);
            if (selectedLanguageIndex > 0) {
              this.constants.languageIndex = selectedLanguageIndex;
            }
            this.generateNoticeList(this.rootMenuElement);
            this.processAlcoholWarning();
          }
        }
      ]
    });

    await alert.present();
  }

  async processAlcoholWarning() {
    if (this.menuData && this.menuData.Menu && this.menuData.Menu.length > 0) {
      if (this.menuData.Menu.some(e=> e.Alcohol)) {
        this.presentAlertAlcohol(this.menuData.Languages[this.constants.languageIndex].Alcohol);
      }
    }
    console.log("processAlcoholWarning : ", this.menuData.Menu.some(e=> e.Alcohol));
  }

  async presentAlertAlcohol(message: string) {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Alcohol Alert',
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            this.setAlcoholPermission(false);
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.setAlcoholPermission(true);
          }
        }
      ]
    });

    await alert.present();
  }

  setAlcoholPermission(isPermitted: boolean) {
    this.constants.alcoholPermission = isPermitted;
    console.log("setAlcoholPermission : ", isPermitted);
  }

  applySiteThemeColor() {
    document.documentElement.style.setProperty('--ion-color-theme', this.constants.siteInfo.siteThemeColor);
    document.documentElement.style.setProperty('--ion-color-theme-shade', this.constants.siteInfo.siteThemeColor);
    document.documentElement.style.setProperty('--ion-color-theme-tint', this.constants.siteInfo.siteThemeColor);
  }

  /**
  
   */

  /**
   * To obtain site data from url structure
   * @param urlHash EO URL Hash
   * @returns SiteData
   */
  getSiteDataFromUrl(urlHash: string) {
    let siteData: SiteData;

    if (this.utilityService.checkIfEoUrl(urlHash)) {
      let str = urlHash.split("#/");
      let str2 = str[1].split("-");

      // init site data
      siteData = {
        siteId: null,
        locationId: null,
        appBuild: null
      }

      // for siteId
      if (str2[0]) {
        siteData.siteId = parseInt(str2[0]);
      }
      // for table number/location
      if (str2[1]) {
        siteData.locationId = parseInt(str2[1]);
      }
      // for table list if all table
      if (str2[1].includes("all")) {
        siteData.locationId = str2[1];
      }

      // for appBuild
      siteData.appBuild = this.constants.appBuild;
      
      // set url fragment..
      this.appUrlFragment = urlHash;
    }
    console.log("processUrl : ", urlHash, siteData);

    return siteData;
    
  }

  // to set site data..
  setSiteData(siteData: SiteData) {
    if (siteData && siteData.siteId && siteData.locationId.toString() && siteData.appBuild) {
      this.constants.siteCreed = siteData;
    }
  }

  
  getQueryDataFromUrl(url: URL) {
    this.loyaltyEmailFromSearchParam = url.searchParams.get("crmemail");
    console.log("getQueryDataFromUrl loyaltyEmailFromSearchParam: ",this.loyaltyEmailFromSearchParam,url.search);
    return url.search;
  }

  /**
   * To generate list of notice to show
   * @param selectedCategory 
   */
  async generateNoticeList(selectedCategory) {
    let noticeList = [];
    if(selectedCategory && selectedCategory.Notice) {
      noticeList.push(selectedCategory);
    }
    if (selectedCategory && selectedCategory.Parents && selectedCategory.Parents.length > 0) {
      selectedCategory.Parents.forEach(element => {
        if(element && element.Notice) {
          noticeList.push(element);
        }
      });
    }
    noticeList.reverse();
    noticeList.forEach(item => {
      this.presentNotice(item);
    });
    // console.log("generateNoticeList : ",noticeList);
    
  }

  /**
   * Show notice based on notice item
   * @param item 
   */
  async presentNotice(item) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: item.Title ? item.Title[this.constants.languageIndex] : "",
      subHeader: item.Desc ? item.Desc[this.constants.languageIndex] : "",
      message: item.Long ? item.Long[this.constants.languageIndex] : "",
      buttons: [
        {
          text: 'Ok',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  
  /**
   * Generate list of tags associated with category
   * @param item category item
   * @returns list of items containing tags
   */
  generateTagList(item) {
    let tagList = [];
    let allDescendants =  this.findAllInheritableDescendants(item);
    if (item && item.Parents && item.Parents.length > 0) {
      item.Parents.forEach(element => {
        if(element && element.Tag && element.Tag[this.constants.languageIndex]) {
          tagList.push(element);
        }
      });
    }

    if (allDescendants && allDescendants.length > 0) {
      allDescendants.forEach(item => {
        if (item && item.Parents && item.Parents.length > 0) {
          item.Parents.forEach(element => {
            if(element && element.Tag && element.Tag[this.constants.languageIndex]) {
              tagList.push(element);
            }
          });
        }
      });
    }
    
    // remove duplicates
    tagList = this.utilityService.removeDuplicatesFromArray(tagList);
    // console.log("generateTagList : ",tagList);
    return tagList;
  }

  resetUrl() {
    if (this.appUrlFragment) {
      this.location.go(this.appUrlFragment);
    }
  }

  /**
   * To assign parent image if no image found
   * @param item 
   * @param parentItem 
   * @returns item with parent image paroperties
   */
  handleParentImage(item,parentItem) {
    if (!item.ThumbID) {
      
      if (item.ImageID) {
        //fallback to ImageID
        item.ThumbID = item.ImageID;
      } else {
        // assign parent thumb if no ImageID
        item.ThumbID = parentItem.ThumbID;        
      }

    }
    if (!item.ImageID) {
      item.ImageID = parentItem.ImageID;
    }
    return item;
  }

  /**
   * To Generate tableList from menu Locations data
   * @param locations Locations data from MenuData
   * @returns tableList
   */
  generateTableLocationsList(locations) : Array<ListType> {
    let tableList: Array<ListType> = [];
    if (locations) {
      let locationData = Object.entries(locations);
      if (locationData && locationData.length > 0) {
        locationData.forEach(element => {
          let table: ListType = {
            name: element[0],
            id: element[1]
          }
          tableList.push(table);
        });
      }
    }
    return tableList;
  }

  getEndDate(InheritTimes, date) {

    let end = this.hasEnd(InheritTimes)
    ? new Date(
      InheritTimes.EndYear ? InheritTimes.EndYear : date.getFullYear(), 
      InheritTimes.EndMonth ? InheritTimes.EndMonth : date.getMonth(),
      InheritTimes.EndDay ? InheritTimes.EndDay : date.getDate(), 
      InheritTimes.EndHour ? InheritTimes.EndHour : date.getHours(), 
      InheritTimes.EndMinute ? InheritTimes.EndMinute : date.getMinutes()
      )
    : null;
    return end;
    
  }

  // To check if pickup table
  isPickup() {
    return this.menuData.PickupLoc && this.menuData.PickupLoc === this.siteData.locationId;
  }

  // To check if delivery table
  isDelivery() {
    return this.menuData.DeliveryLoc && this.menuData.DeliveryLoc === this.siteData.locationId;
  }



}
