import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Data } from 'src/app/models/data.model';


@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {

  constructor(
    private http: HttpClient,
  ) { }

   // Basic service..
   postData(data: Data) {
    return this.http.post(data.url, data.data);
  }

  getData(data: Data) {
    return this.http.get(data.url);
  }

  // Service without headers..
  postDataNoHeaders(data: Data) {
    return this.http.post(data.url, data.data);
  }

  getDataNoHeaders(data: Data) {
    return this.http.get(data.url);
  }

  postDataWithHeaders(data: Data, headers) {
    // let headers = {
    //   'Authorization': 'Bearer ' + this.companyService.currentUserToken.token,
    //   "Content-Type": "application/json",
    // };
    const requestOptions = {
      headers: headers
    };
    return this.http.post(data.url, data.data, requestOptions);
  }

  getDataWithHeaders(data: Data, headers) {
    const requestOptions = {
      headers: headers
    };
    return this.http.get(data.url, requestOptions);
  }

}
