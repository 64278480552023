import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/shared/constants/constants.service';

@Component({
  selector: 'app-eositeredirect',
  templateUrl: './eositeredirect.page.html',
  styleUrls: ['./eositeredirect.page.scss'],
})
export class EositeredirectPage implements OnInit {

  constructor(
    public constants: ConstantsService,
  ) { }

  ngOnInit() {
  }



  // Events..
  onClickGoToEoSite() {
    this.goToEoExternalHomePage();
  }

  // Navigation..
  goToEoExternalHomePage() {
    window.location.href = this.constants.eoRedirectWebsiteUrl;
  }

}
