import { Injectable } from '@angular/core';
import { Data, TevalisMemberType, TevalisSignupUserCreedType, TevalisUserGetPayloadType } from 'src/app/models/data.model';
import { CustomHttpService } from '../../customhttp/custom-http.service';
import { ConstantsService } from '../../shared/constants/constants.service';
import { Storage } from '@ionic/storage-angular';
import { MenusService } from '../../menus/menus.service';
import { UtilityService } from '../../utility/utility.service';


@Injectable({
  providedIn: 'root'
})
export class TevalisService {

  constructor(
    private constants: ConstantsService,
    private customHttp: CustomHttpService,
    private storage: Storage,
    public menusService : MenusService,
    public utilityService: UtilityService,


  ) { }

  /**
   * To authanticate tevalis user
   * @param user tevalis user data payload
   * @returns Tevalis member object
   */
  authanticate(user: TevalisUserGetPayloadType) {
    return new Promise((resolve, reject) => {
      let data: Data = { url: '', data: '' };
      data.url = this.constants.tevalisUrlSignin //+ "/signin";
      data.data = user;

      this.customHttp.postData(data).subscribe(
        (resp: any) => {
          this.setLoyaltySkipStatus(false);
          resolve(resp);
        },
        // Error Case..
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * To remove user data from local and service variable
   */
  unAuthorize() {
    this.storage.set(this.constants.storageTevalisCurrentMember, '{}');
    this.menusService.tevalisLoyaltyUserData = null;
    this.setLoyaltySkipStatus(false);
  }

  /**
   * Saves tevalis member data to localstorage
   * @param currentMember tevalis menber obj
   */
  async saveCurrentMemberAsync(currentMember: TevalisMemberType) {
    currentMember.SiteID = this.menusService.siteData.siteId;
    await this.storage.set(this.constants.storageTevalisCurrentMember, JSON.stringify(currentMember));
  }

  /**
   * To get saved user from localstorage
   * @returns Tevalis user object
   */
  async getCurrentMemberFromLocalAsync() {
    const currentMember = await this.storage.get(this.constants.storageTevalisCurrentMember);
    if (currentMember) {
      let member: TevalisMemberType = JSON.parse(currentMember);
      return member;
    }
    return '{}';
  }

  /**
   * To add new user to tevalis crm
   * @param user TevalisSignupUserCreedType
   * @returns TevalisMemberType
   */
  addNewUser(user: TevalisSignupUserCreedType) {
    return new Promise((resolve, reject) => {
      let userGeneratedDob = this.utilityService.getUtcTimestampFormatX(user.dob);
      // user.Dob = this.companyService.getUtcTimestampFormatX(user.Dob);
      console.log("addNewUser: ", user);
      //"DOB": "/Date(818553600000+0000)/", //old date format
      let data: Data = { url: '', data: '' };
      data.url = this.constants.tevalisUrlSignup;
      data.data =
        `{
          "Address1": "",
          "Address2": "",
          "AuthCode": null,
          "CardNo": "${this.utilityService.generateCardNumberForNewUser()}",
          "County": "",
          "DOB": "/Date(${userGeneratedDob})/",
          "Email": "${user.userEmail}",
          "EmailOptOut": false,
          "ExpiryDate": "/Date(-62135596800000+0000)/",
          "FirstName": "${user.first_name}",
          "IsActive": true,
          "IsMale": true,
          "LastName": "${user.last_name}",
          "LevelID": 1,
          "MemberID": 0,
          "MobileNo": "",
          "PhoneNo": "",
          "PostCode": "${user.password}",
          "Title": "",
          "Town": "",
          "SiteId": ${this.menusService.siteData.siteId}
        }`;


      // Check if user already exists..
      let tevalisUserGetPayload: TevalisUserGetPayloadType = {
        siteid: this.menusService.siteData.siteId,
        email: user.userEmail
      }
      // const userCreed: TevalisSignupUserCreedType = { userEmail: user.userEmail, password: user.password };
      this.authanticate(tevalisUserGetPayload).then((resp: TevalisMemberType) => {
        console.log(resp);
        reject(resp);
      }).catch((err) => {
        console.log(err);
        if (err.status == 404) { // User donot exist good to go..

          this.customHttp.postData(data).toPromise().then((resp: any) => {

            resolve(resp);

          }).catch((error) => {
            reject(error);
          });

        } else {
          //Some other error
          reject(err);
        }
      });

    });
  }

  /**
   * To update existing user with member id and updated data
   * @param user user obj with Memberid
   * @returns user data
   */
  updateExistingUser(user: any) {
    return new Promise((resolve, reject) => {
      user.siteid = this.menusService.siteData.siteId;
      console.log("updateExistingUser: ", user);
      let data: Data = { url: '', data: '' };
      data.url = this.constants.tevalisUrlUpdateMember;
      data.data = JSON.stringify(user);
      this.customHttp.postData(data).toPromise().then((resp: any) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }


  /**
   * Update lastname of user with memberId
   * @param member TevalisMemberType
   * @returns TevalisMemberType
   */
  updateMemberNameWithId(member: any) {
    return new Promise((resolve, reject) => {
      member.LastName = member.LastName + ' | ' + member.MemberID;
      member.siteid =  this.menusService.siteData.siteId
      let data: Data = { url: '', data: '' };
      data.url = this.constants.tevalisUrlUpdateMember;
      data.data = `${JSON.stringify(member)}`

      this.customHttp.postData(data).toPromise().then((resp: any) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  async setLoyaltySkipStatus(status: boolean) {
    return await this.storage.set(this.constants.storageLoyaltySkip, status);
  }

  async getLoyaltySkipStatus() {
    return await this.storage.get(this.constants.storageLoyaltySkip);
  }


}
