import { Component } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { EositeredirectPage } from './pages/eositeredirect/eositeredirect.page';
import { MenusService } from './services/menus/menus.service';
import { ConstantsService } from './services/shared/constants/constants.service';
import { UtilityService } from './services/utility/utility.service';

import { Storage } from '@ionic/storage-angular';
import { TevalisService } from './services/auth/tevalis/tevalis.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isSiteDataLoaded: boolean = false;
  
  constructor(
    public menusService : MenusService,
    private navCtrl: NavController,
    public utilityService: UtilityService,
    public constants: ConstantsService,
    public modalController: ModalController,
    public alertController: AlertController,
    private storage: Storage,
    public tevalisService: TevalisService,


  ) {

  }

  ngOnInit() {
    this.processUrl();
    this.initStorage();
  }

  processUrl() {
    let url = this.menusService.appUrl = new URL(window.location.href);

    let siteData = this.menusService.getSiteDataFromUrl(url.hash);
    this.menusService.getQueryDataFromUrl(url);
    if (siteData) {
      if (siteData.siteId && siteData.locationId.toString()) {
        this.menusService.setSiteData(siteData);
        this.goToLoadingPage();
      } else {
        this.presentModalEoHome();
        this.utilityService.presentAlert("Incorect Url!");
      }
    } else {
      this.presentModalEoHome();
    }
  }

  changeTable() {
    this.presentAlertChangeTable();
  }

  initStorage() {
    this.storage.create();
  }

  // Modals..
  async presentModalEoHome(message?) {
    const modal = await this.modalController.create({
      component: EositeredirectPage,
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      componentProps: {
        message: message
      }
    });

    modal.onDidDismiss().then(() => {
     
    });

    return await modal.present();
  }

  // Events..
  onClickAboutUs() {
    this.utilityService.openExternalLink("https://extraorder.app/about/");
  }

  onClickContactUs() {
    this.utilityService.openExternalLink("https://extraorder.app/contact-us/");

  }

  onClickPrivacyPolicy() {
    this.utilityService.openExternalLink("");

  }

  onClickChangeTable() {
    this.changeTable();
  }

  onClickSigninSideMenuBtn() {
    this.tevalisService.unAuthorize();
    this.goToSigninPage();
  }

  onClickSignoutSideMenuBtn() {
    this.tevalisService.unAuthorize();
    this.goToSigninPage();
  }
  
  // Navigation..
  goToHomePage() {
    this.navCtrl.navigateRoot('/home',{ skipLocationChange: true });
  }

  goToLoadingPage() {
    this.navCtrl.navigateRoot('/loading',{ skipLocationChange: true });
  }

  goToTableSelectionPage() {
    this.navCtrl.navigateRoot('/tableselection',{ skipLocationChange: true });
  }

  goToSigninPage() {
    this.navCtrl.navigateRoot('/tevalis-signin',{ skipLocationChange: true });
  }
  

  // Alerts..
  
  async presentAlertChangeTable() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Notice',
      message: 'Reload required.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Ok',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }
  
  
}
