import { Quote } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { SiteData } from 'src/app/models/data.model';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor() { }

  // App Site URLs..
  // private URL_SUB_DOMAIN = "qr"; // Live domain
  private URL_SUB_DOMAIN = "qt"; // Test domain
  private URL = "https://"+this.URL_SUB_DOMAIN+".extraorder.app"; ///5710353417633792-10t'; // Test Url
  // private URL = "http://localhost:8082"; ///5710353417633792-10t'; // Test Url
  //private URL = "http://127.0.0.1:8080"; // Local dev Url 
  private BASE_URL = this.URL;
  public STORE_API_BASE_URL = this.BASE_URL + "/api";
  public eoRedirectWebsiteUrl = "https://extraorder.app"

  // test url https://qt.extraorder.app/5766220003409920-0t

  public menuRequestHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
  };

  public orderRequestHeaders = {
    "Content-Type": "application/json",
  };

  public confirmOrderRequestHeaders = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  // SiteData..
  public siteCreed: SiteData = 

  {
    siteId:  5714489739575296,//"5714489739575296", //"5766220003409920", //
    locationId: 1,
    appBuild: 28,
  }

  public appBuild: number = 28;

  public siteInfo = {
    siteCreed: this.siteCreed,
    platform: 1,
    siteThemeColor: "#c22629",
    version : 0,
    rounding: 1,
    tip: false
  }

  public languageIndex = 0;
  public alcoholPermission: boolean = false;

  // Stripe creed..
  stripePubKey;
  stripeClientID;


  // Image..
  placeholderImagePath = "/assets/img/placeholder2.jpg";
  placeholderBgImagePath = "/assets/img/placeholderbg.jpg"

  // Storage Keys
  storageTevalisCurrentMember = 'tevalis_current_member';
  storageLoyaltySkip = 'tevalis_loyalty_skip';

  // LoyaltyUrl
  // private tevalisUrl = this.URL;
  public tevalisBaseUrl = this.URL + "/api/pos/tevalis";
  public tevalisUrlSignin = this.tevalisBaseUrl + "/signin"
  public tevalisUrlSignup = this.tevalisBaseUrl + "/signup"
  public tevalisUrlUpdateMember = this.tevalisBaseUrl + "/update"
  

  // menu pickup required fields
  public menuPickupRequiredFields = [
    "Name",
    "Phone",
    "Pickup Date",
    "Pickup Time",
  ];

  // menu delivery required fields
  public menuDeliveryRequiredFields = [
    "Name",
    "Phone",
    "Unit/House Number",
    "Street Name",
    "Suburb",
    "City",
  ];

  // upsell identifire (should be lowercase)
  upsellPopupUniqueText = "Would you also like".toLowerCase();

}
