import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'itemlist',
    loadChildren: () => import('./pages/itemlist/itemlist.module').then( m => m.ItemlistPageModule)
  },
  {
    path: 'itemdetails',
    loadChildren: () => import('./pages/itemdetails/itemdetails.module').then( m => m.ItemdetailsPageModule)
  },  {
    path: 'loading',
    loadChildren: () => import('./pages/loading/loading.module').then( m => m.LoadingPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'requiredfield',
    loadChildren: () => import('./pages/modals/requiredfield/requiredfield.module').then( m => m.RequiredfieldPageModule)
  },
  {
    path: 'paymentprocess',
    loadChildren: () => import('./pages/modals/paymentprocess/paymentprocess.module').then( m => m.PaymentprocessPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./pages/modals/success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'stripecheckout',
    loadChildren: () => import('./pages/stripecheckout/stripecheckout.module').then( m => m.StripecheckoutPageModule)
  },
  {
    path: 'tableselection',
    loadChildren: () => import('./pages/tableselection/tableselection.module').then( m => m.TableselectionPageModule)
  },
  {
    path: 'eositeredirect',
    loadChildren: () => import('./pages/eositeredirect/eositeredirect.module').then( m => m.EositeredirectPageModule)
  },
  {
    path: 'tevalis-signup',
    loadChildren: () => import('./pages/tevalis-auth/tevalis-signup/tevalis-signup.module').then( m => m.TevalisSignupPageModule)
  },
  {
    path: 'tevalis-signin',
    loadChildren: () => import('./pages/tevalis-auth/tevalis-signin/tevalis-signin.module').then( m => m.TevalisSigninPageModule)
  },
  {
    path: 'tevalis-forgotpassword',
    loadChildren: () => import('./pages/tevalis-auth/tevalis-forgotpassword/tevalis-forgotpassword.module').then( m => m.TevalisForgotpasswordPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
